

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import HomeView from '../views/HomeView.vue'
import LoginApp from '../views/LoginApp.vue'
import CreateColaboradorApp from '../views/colaboradores/CreateColaboradorApp.vue'
import IndexColaboradorApp from '../views/colaboradores/IndexColaboradorApp.vue'
import jwt_decode from 'jwt-decode'
//import DashboardApp from '../views/DashboardApp.vue'
//import CreateIngreso from '../views/ingreso/CreateIngresoApp.vue'

Vue.use(VueRouter)

const routes = [
  //COLABORADORES Y MAS
  {path: '/',name: 'login', component: LoginApp, meta: { requiresAuth: false }},
  {path: '/colaborador',name: 'colaborador-index',component: IndexColaboradorApp,meta: { requiresAuth: true }},
  {path: '/colaborador/create',name: 'colaborador-create',component: CreateColaboradorApp, meta: { requiresAuth: true }},
  {path: '/colaborador/edit/:id',name: 'colaborador-edit',component: ()=> import('@/views/colaboradores/EditColaboradorApp.vue'),meta: { requiresAuth: true }},
  {path: '/dashboard',name: 'dashboard',component: ()=> import('@/views/DashboardApp.vue') ,meta: { requiresAuth: true }},

//PRODUCTOS
  {path: '/producto',name: 'producto-index',component: ()=> import('@/views/productos/IndexProductoApp.vue'), meta: { requiresAuth: true }},
  {path: '/producto/galeria/:id',name: 'producto-galeria',component: ()=> import('@/views/productos/GaleriaProductoApp.vue'), meta: { requiresAuth: true }}, 
  {path: '/producto/create',name: 'producto-create',component: ()=> import('@/views/productos/CreateProductoApp.vue'), meta: { requiresAuth: true }},
  {path: '/producto/edit/:id',name: 'producto-edit',component: ()=> import('@/views/productos/EditProductoApp.vue'),meta: { requiresAuth: true }},
  
  
  //Banners
  {path: '/banner',name: 'banner-index',component: ()=> import('@/views/banner/BannerIndexProductoApp.vue'),meta: { requiresAuth: true }},
  {path: '/banner/create',name: 'banner-create',component: ()=> import('@/views/banner/BannerProductoApp.vue'),meta: { requiresAuth: true }},
  {path: '/banner/edit/:id',name: 'banner-edit',component: ()=> import('@/views/banner/BannerEditProductoApp.vue'),meta: { requiresAuth: true }},


    //ImagenInicio
    {path: '/imagenInicio',name: 'imagenInicio-index',component: ()=> import('@/views/img_inicio/ImagenIniIndexApp.vue'),meta: { requiresAuth: true }},
    {path: '/imagenInicio/create',name: 'imagenInicio-create',component: ()=> import('@/views/img_inicio/ImagenIniCreateApp.vue'),meta: { requiresAuth: true }},
    {path: '/imagenInicio/edit/:id',name: 'imagenInicio-edit',component: ()=> import('@/views/img_inicio/ImagenIniEditApp.vue'),meta: { requiresAuth: true }},
  
  /*   {path: '/about', name: 'about'}, */

//INGRESOS
{path: '/ingreso/create',name: 'ingreso-create',component: ()=> import('@/views/ingreso/CreateIngresoApp.vue'), meta: { requiresAuth: true }},
{path: '/ingreso',name: 'ingreso-index',component: ()=> import('@/views/ingreso/IndexIngresoApp.vue'), meta: { requiresAuth: true }},
{path: '/ingreso/:id',name: 'ingreso-detalle',component: ()=> import('@/views/ingreso/DetalleIngresoApp.vue'), meta: { requiresAuth: true }},
//VENTAS
{path: '/ventas',name: 'ventas-index',component: ()=> import('@/views/ventas/VentasIndexApp.vue'), meta: { requiresAuth: true }},
{path: '/ventas/:id',name: 'ventas-detalle',component: ()=> import('@/views/ventas/VentasDetalleApp.vue'), meta: { requiresAuth: true }},

//CATEGORIAS

{path: '/categorias',name: 'categorias',component: ()=> import('@/views/categorias/IndexCategoriaApp.vue'), meta: { requiresAuth: true }},
//Marcas

{path: '/marcas',name: 'marcas',component: ()=> import('@/views/marcas/IndexMarcasApp.vue'), meta: { requiresAuth: true }},

] 

const router = new VueRouter({
  mode: 'history', 
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // ...
  // explicitly return false to cancel the navigation
  if(to.matched.some(item=>item.meta.requiresAuth)){

    if(!store.state.token){
      next({
        name: 'login'
      })
    }

    try {
      jwt_decode(store.state.token)
    } catch (error) {
      next({
        name: 'login'
      })
    }

    if(jwt_decode(store.state.token).exp*1000<= new Date().getTime()){
      console.log('kjsdhfskdjfh');
      next({
        name: 'login'
      })
    }
    next()
    console.log('privada');
  }else{
    console.log('publica');
    next()
  }
 
})

export default router
